@import '../../styles/variables.scss';
.Notification{
	margin-left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 40px;
	min-width: 40px;
	height: 40px;
	.numberBadge{
		position: absolute;
		top: 3px;
		right: 3px;
		width: 18px;
		height: 18px;
		background-color: red;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 12px;
		label{
			color: white;
			font-size: 10px;
		}
	}
	svg{
		height: 70%;
	}
}

// NOTIFICATION ITEM
.NotificationItem{
	width: auto;
	padding: 12px;
	border-bottom: 1px solid rgba($color3,0.3);
	.notificationTitleContainer{
	}
	.notificationContentContainer{
		p{
			line-height: 20px;
			text-overflow: ellipsis;
			overflow: hidden;
			font-size: 12px;
			max-lines: 2;
			line-clamp: 2;
			// max-height: 40px;

			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
		}
	}
	transition: all 150ms ease;
}
.unitTypeContainer{
	h5{
		line-height: 20px;
		text-overflow: ellipsis;
		overflow: hidden;
		// font-size: 12px;
		max-lines: 1;
		line-clamp:1;
		// max-height: 40px;

		display: -webkit-box;
		-webkit-line-clamp:1;
		-webkit-box-orient: vertical;  
	}
}
.NotificationItem:hover{
	background-color: rgba($color4,1);
	color: white
}
.NotificationItem:last-child{
	border-bottom: none;
}