@import '../../styles/variables.scss';

.InputField{
	border-radius: 5px;
	width: min-content;
	height: min-content;
	// overflow: hidden;
	box-shadow: 
		4.5px 4.5px 8px rgba($darkShadow,0.6);
	justify-content: center;
	align-items: center;
	max-width: 80% !important;
	input,textarea{
		// border-radius: 6px;
		padding: 8px 16px;
		color:black;
		border: 2px solid $color2;
	}
	input:focus{
		outline-color: $color4;
	}
	textarea{
		border: none;
		width: 100%;
		// max-width: 100% !important;
		height: 100%;
		padding: 0;
		resize: none;
		outline:none;
		overflow: visible;
		margin-bottom: 2px;
	}
}
.InputField.textarea{
	padding: 8px 16px;
	background-color: white;
	max-width: 80vw !important;
	width: 450px;
	height: 300px;
}
