.SubPage{
	flex: 1;
	flex-direction: column;
	// width: 100vw;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
}

.TabPage{
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	flex-direction: row;
	overflow-y: auto;
	.tableContainer{
		width: min-content;
		// min-width: min-content;
		max-width: min-content;
		height: min-content;
		min-height: min-content;
		max-height: min-content;
		max-width: 100%;
		overflow-x: auto;
		// padding: 16px 16px;
		overflow-y: visible;
		.tableInnerContainer{
			// margin-right: 16px;
			padding: 16px 16px;
			width: min-content;
		}
	}
	flex-wrap: wrap;
}

@media only screen
and (max-width: 1024px){
	.TabPage{
		// flex-direction: column;
		// .Unit{
		// 	margin-bottom: 24px;
		// }
		// .UnitType{
		// 	margin-left: 0px;
		// }
	}
}