@import '../styles//variables.scss';
.Detail{
	display: flex !important;
	flex-direction: column !important;

	flex-wrap: nowrap !important;
	.ActionBar{
		// margin-left: 16px;
		padding: 8px 16px;
		padding-top: 16px;
		padding-bottom: 0px;
		// margin-bottom: 16px;
		display: flex;
		// justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		flex-direction: row;
		.Neu{
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 16px;
			margin-bottom: 16px;
			width: min-content;
			padding: 0px 16px;
			h5{
				white-space: nowrap;
			}
		}
		.Neu:last-child{
			margin-right: 0;
		}
	}
	.inputFieldContainer{
		margin-left: 16px;
		margin-bottom: 16px;
		h3.title{
			margin-bottom: 3px;
		}
		.innerinputFieldContainer.row{
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			.InputField:first-child{
				margin-right: 16px;
			}
		}
	}
	.inputFieldContainer.slider{
		max-width: 100%;
		min-height: min-content;
		overflow-x: auto;
		margin-left: 0;
		margin-bottom: 0;
		h3{
			padding-left: 16px;
		}
		.innerinputFieldContainer.row{
			padding: 16px 16px;
			padding-top: 8px;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			// max-width: 100%;
			width: min-content;
			.InputField{
				margin-right: 16px;
				max-width: none !important;
			}
			.InputField::last-child{
				margin-right: 0px;
			}
		}
	}
}

.InputImage{
	width: 150px;
	height: 150px;
	background-color: white;
	box-shadow: 4.5px 4.5px 8px rgba($darkShadow,0.6);
	border-radius: 5px;
	outline: 4px dashed rgba($color3,1);
	position: relative;
	.inputPlus{
		height: 25%;
		width: 25%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		svg{
			fill: $color3
		}
	}
	img{
		width: 100%;
		height: 100%;
	}
}
.InputImage.available{
	outline-width: 0px;
}