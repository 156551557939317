@import '../../styles/variables.scss';
.SideBar{
	position: fixed;
	left: 0;
	width: 256px;
	height: 100%;
	background-image: url('../../assets//images/office.webp');
	background-position: left;
	background-size: cover;
	position: relative;
	z-index: 3333;
	display: flex;
	// justify-content: center;
	flex-direction: column;
	align-items: center;
	.titleContainer{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		z-index: 3;
		height: 48px;
		width: 80%;
		margin: 16px 0px;
		cursor: pointer;
		.sideBarLogoContainer{
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(255,255,255,0.9);
			backdrop-filter: blur(6px);
			height: 40px;
			width: 40px;
			border-radius: 24px;
			margin-right: 12px;
			z-index: 3;
			img{
				z-index: 4;
				height: 60%;
			}
		}
		h1{
			color: white;
		}
	}
	.titleSeparator{
		width: 90%;
		min-width: 90%;
		height: 2px;
		min-height: 2px;
		content: "";
		background-color: white;
		z-index: 3;
		border-radius: 5px;
		margin-bottom: 16px;
	}
	.Item{
		width: 80%;
		height: 48px;
		z-index: 3;
		display: flex;
		justify-content: flex-start;
		padding-left: 16px;
		align-items: center;
		border-radius: 5px;
		margin-bottom: 16px;
		transition: background 300ms ease;
		cursor: pointer;
		label{
			color: $color1;
			transition: color 300ms ease;
		}
		.icon{
			width: 24px;
			height: 24px;
			fill: white;
			transition: fill 300ms ease;
			margin-right: 16px;
		}
	}
	.Item:hover, .Item.active{
		background-color: $color2;
		label{
			color: black;
		}
		.icon{
			width: 24px;
			height: 24px;
			fill: black;
		}
	}
	.overlay{
		width: 256px;
		height: 100%;
		content: "";
		display: block;
		opacity: .8;
		position: absolute;
		top: 0;
		left: 0;
		background: #000;
		z-index: 2;
	}
}
.mainLeftContainer{
	min-width: 256px;
	height: 100%;
}

@media only screen 
and (max-width: 1024px) 
{
	.mainLeftContainer{
		min-width: 0px;
	}
	.SideBar{
		// width: 0px;
		transition: transform 300ms ease;
		transform: translate(-100%,0%);
		// overflow: hidden;
	}
	.SideBar.active{
		transform: translate(0%,0%);
	}
}