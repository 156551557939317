@import '../styles/variables.scss';

.Electricity{
	flex-direction: column !important;
	flex-wrap: nowrap;
	width: 100%;
	// .UnitSelector{
	// 	margin-left: 16px;
	// 	width: 256px;
	// 	margin-top: 16px;
	// }
}	