@import '../../styles/variables.scss';

.Modal{
	position: fixed;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	z-index: 999999999;
	background-color: rgba(#2c2c2c,0.5);
	backdrop-filter: blur(3px);
	display: flex;
	justify-content: center;
	align-items: center;
	display: none;
	opacity: 0;
	transition: opacity 300ms ease;
	.component{
		max-width: 80vw;
		max-height: 100vh;
	}
	.innerModal{
		width: min-content;
		max-width: 100%;
		height: min-content;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.Modal.active{
	display: flex;
	opacity: 1;
}