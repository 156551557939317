@import '../../styles/variables.scss';

.Table{
	width: min-content;
	height: min-content;
	// div{
		// -webkit-tap-highlight-color: red;
		-webkit-touch-callout: text; /* iOS Safari */
		-webkit-user-select: text;   /* Safari */
		-khtml-user-select: text;    /* Konqueror HTML */
		-moz-user-select: text;      /* Firefox */
		-ms-user-select: text;       /* Internet Explorer/Edge */
		user-select: text;           /* Non-prefixed version, currently supported by Chrome and Opera */
	// }
	.headContainer{
		display: flex;
		flex-direction: row;
		padding: 16px 24px;
		padding-bottom: 0px;
		.titleContainer{
			display: flex;
			flex: 1;
			align-items: center;
			h3{
				font-weight: bold;
			}
		}
	}
	table{
		// border-spacing: 24px 8px; 
		border-spacing: 0px 0px;
		padding: 16px;
		padding-top: 8px;
		thead{
			tr{
				th{
					white-space: nowrap;
					padding: 4px 12px;
					font-size: 14px;
				}
			}
		}
		tbody{
			tr{
				td{
					padding: 4px 12px;
					white-space: nowrap;
					font-size: 12px;
				}
				td:first-child{
					border-top-left-radius: 100px;
					border-bottom-left-radius: 100px;
					// justify-content: center;
					text-align: center;
				}
				td:last-child{
					border-top-right-radius: 100px;
					border-bottom-right-radius: 100px;
				}
				transition: background 150ms ease;
			}
			tr:hover{
				background-color: rgba($color4,0.3);
				.Neu{
					box-shadow: 
						4.5px 4.5px 8px rgba($darkShadow,0.6), 
						-4.5px -4.5px 8px rgba($darkShadow, 0.5),
						inset 4.5px 4.5px 8px rgba($darkShadow,0),
						inset -4.5px -4.5px 8px rgba($lightShadow, 0);
				}
			}
		}
	}
}

.TableActionButton{
	width: min-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 24px;
	min-height: min-content;
	max-height: min-content;
	padding: 8px 16px;
	.iconContainer{
		height: 100%;
		min-height: 100%;
		max-height: 100%;
		// width: height;
		margin-right: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg{
			height: 70%;
			fill: $color4;
		}
	}
	.actionTitleContainer{
		h3{
			color: $color4
		}
	}
}