@import './variables.scss';
@import './print.scss';
html{
	height: 100vh;
	width: 100vw;
	background-color: $mainColor;
}
body{
	margin: 0;
	width: 100%;
	height: 100%;
}
#root{
	width: 100%;
	height: 100%;
}
.App{
	width: 100%;
	max-width: 100%;
	min-width: 100%;
	height: 100%;
	max-height: 100%;
	min-height: 100%;
	overflow: hidden;
}
p,h1,h2,h3,h4,h5,label,tr,input{
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	// font-weight: 400;
}

label:hover{
	cursor: pointer;
}
.borderBox{
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}
div,p{
	// -webkit-tap-highlight-color: transparent;
	// -webkit-touch-callout: none; /* iOS Safari */
	// -webkit-user-select: none;   /* Safari */
	// -khtml-user-select: none;    /* Konqueror HTML */
	// -moz-user-select: none;      /* Firefox */
	// -ms-user-select: none;       /* Internet Explorer/Edge */
	// user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
}
div{
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Safari */
	-khtml-user-select: none;    /* Konqueror HTML */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
}

.animation.rotate{
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-ms-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cursor.pointer:hover{
	cursor: pointer;
}

.main_bg{
	background-color: $mainColor;
}
.dropShadow{
	box-shadow: 4.5px 4.5px 8px rgba($darkShadow,0.6);
}
.modalPadding{
	padding: 24px
}
.radius{
	border-radius: 5px;
}
.hover:hover{
	cursor: pointer;
}
.hover.whitening:hover{
	color: white
}
.hover.tableItem:hover{
	background-color: rgba(#2c2c2c, 0.5);
}

/* width */
::-webkit-scrollbar {
	width: 3px;
	height: 3px;
	border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1; 
	border-radius: 5px;

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.TabMenu::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	border-radius: 5px;
}


.RateTable{
	.unitTypeContainer{
		transition: width 300ms ease;
	}
}
