@import '../../styles//variables.scss';

.TabMenu{
	width: 100%;
	max-width: 100%;
	min-width: 100%;
	display: flex;
	flex-direction: row;
	height: min-content;
	min-height: 40px;
	max-height: 40px;
	padding: 16px 0;
	overflow-x: auto;
	.scrollHack{
		display: flex;
		flex-direction: row;
		width: min-content;
		height: min-content;
		padding: 0 16px;
		.Neu{
			margin-right: 16px;
		}
		.Neu:last-child{
			margin-right: 0px;
		}
	}
	.Tab{
		display: flex;
		justify-content: center;
		align-items: center;
		width: min-content;
		padding: 0 16px;
		margin-right: 0px;
		
	}
	.Tab.Neu.active{
		background-color: #2c2c2c;
		color: white;
		box-shadow: 
			4.5px 4.5px 8px rgba($darkShadow,0.6), 
			-4.5px -4.5px 8px rgba($lightShadow, 0.5);
	}
}
