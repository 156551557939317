@import '../../styles//variables.scss';
.Neu{
	width: 40px;
	height: 40px;
	// margin-top: 16px;
	// margin-left: 16px;
	border-radius: 5px;
	// border-radius: 5px;
	/* For Neumorphism Effect */
	background-color:$mainColor;
	// box-shadow: 
	// 	4.5px 4.5px 8px rgba($darkShadow,0.6), 
	// 	-4.5px -4.5px 8px rgba($lightShadow, 0.5);
	// transition: border 0.3s ease;
	// border: 1px solid darken($mainColor, 0%);
	// animation: shadowFadeIn 0.1s;
	// transition: box-shadow 0.1s ease-in-out;
	// * WATCH THIS SHADOW * //
	box-shadow: 
		4.5px 4.5px 8px rgba($darkShadow,0.6), 
		-4.5px -4.5px 8px rgba($lightShadow, 0.5),
		inset 4.5px 4.5px 8px rgba($darkShadow,0), 
		inset -4.5px -4.5px 8px rgba($lightShadow, 0);
		
	transition: all 0.2s ease-in-out;
	/* For Neumorphism Effect */
	position: relative;
	.DropDown{
		position: absolute;
		bottom: -10px;
		left: 0;
		background-color: rgba($color2,0.5);
		border-radius: 5px;
		backdrop-filter: blur(4px);
		width: 100%;
		height: 0px;
		// min-height: 0px;
		// max-height: 0px;
		transform: translate(0,100%);
		transition: all 300ms ease;
		overflow: hidden;
		z-index: 20;
	}
}
.Neu:hover{
	cursor: pointer;
}
.Neu.active{
	// box-shadow: inset 4.5px 4.5px 8px rgba($darkShadow,0.6), inset -4.5px -4.5px 8px rgba($lightShadow, 0.5), 4.5px 4.5px 8px rgba($darkShadow,0.6), -4.5px -4.5px 8px rgba($lightShadow, 0.5);	
	// animation: shadowFadeOut 0.1s;
	// transition: box-shadow 0.1s ease;

	box-shadow: 
		4.5px 4.5px 8px rgba($darkShadow,0.6), 
		-4.5px -4.5px 8px rgba($lightShadow, 0.5),
		inset 4.5px 4.5px 8px rgba($darkShadow,0.6), 
		inset -4.5px -4.5px 8px rgba($lightShadow, 0.5);

	

	// background-color: #2c2c2c;
	// color: white;

	.DropDown{
		height: min-content;
		min-height: min-content;
		max-height: min-content;
		box-shadow: 5px 5px 16px rgba($darkShadow,0.6);
	}
}

// ! NOT USED ANYMORE RTFM ??
@keyframes shadowFadeOut {
	0% { 
		box-shadow: 
			inset 0px 0px 8px rgba($darkShadow,0.6), 
			inset 0px 0px 8px rgba($lightShadow, 0.5), 
			4.5px 4.5px 8px rgba($darkShadow,0.6), 
			-4.5px -4.5px 8px rgba($lightShadow, 0.5);	
	}
	50% { 
		box-shadow: 
			inset 2.25px 2.25px 8px rgba($darkShadow,0.6), 
			inset -2.25px -2.25px 8px rgba($lightShadow, 0.5), 
			4.5px 4.5px 8px rgba($darkShadow,0.6), 
			-4.5px -4.5px 8px rgba($lightShadow, 0.5);	
	}
	100% { 
		box-shadow: 
			inset 4.5px 4.5px 8px rgba($darkShadow,0.6), 
			inset -4.5px -4.5px 8px rgba($lightShadow, 0.5), 
			4.5px 4.5px 8px rgba($darkShadow,0.6), 
			-4.5px -4.5px 8px rgba($lightShadow, 0.5);	
	}
}
@keyframes shadowFadeIn {
	0% {
		box-shadow: 
			inset 4.5px 4.5px 8px rgba($darkShadow,0.6), 
			inset -4.5px -4.5px 8px rgba($lightShadow, 0.5), 
			4.5px 4.5px 8px rgba($darkShadow,0.6), 
			-4.5px -4.5px 8px rgba($lightShadow, 0.5);	
	}
	50% { 
		box-shadow: 
			inset 2.25px 2.25px 8px rgba($darkShadow,0.6), 
			inset -2.25px -2.25px 8px rgba($lightShadow, 0.5), 
			4.5px 4.5px 8px rgba($darkShadow,0.6), 
			-4.5px -4.5px 8px rgba($lightShadow, 0.5);	
	}
	100% { 
		box-shadow: 
			inset 0px 0px 8px rgba($darkShadow,0.6), 
			inset 0px 0px 8px rgba($lightShadow, 0.5), 
			4.5px 4.5px 8px rgba($darkShadow,0.6), 
			-4.5px -4.5px 8px rgba($lightShadow, 0.5);	
	}
}