.ProfileCard{
  .dropDownItem{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    p{
      margin-right: 24px;
    }
    transition: background-color 300ms ease;
  }
  .dropDownItem:hover{
    background-color: #22304A;
    color: white
  }
}