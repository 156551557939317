// .Label{
// 	transition: all 300ms ease;
// 	.closeButton{
// 		transition: all 300ms ease;
// 	}
// 	.closeButton:hover{
// 		background-color: rgba(34, 48, 74, 1);
// 	}
// }
.closeButton{
	transition: all 300ms ease;
}
.closeButton:hover{
	background-color: rgba(34, 48, 74, 1) !important;
}
// .abel