@import './variables.scss';
.Login{
	// Skeleton
	display: flex;
	justify-content: center;
	align-self: center;
	// Sizing
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	overflow: hidden;
	// Background
	background-image: url('../assets/images/office.webp');
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	.loginModal{
		width: 400px;
		max-width: 80%;
		height: 80%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: rgba(255,255,255,0.4);
		backdrop-filter: blur(6px);
		border-radius: 20px;
		.titleContainer{
			margin-top: 20px;
			margin-bottom: 20px;
			display: flex;
			flex-direction: row;
			.logoContainer{
				img{

				}
			}
			.titleHeadingContainer{
				h1{
					font-size: 40px;
				}	
			}
		}
		.inputContainer{
			display: flex;
			flex-direction: row;
			width: 80%;
			height: 30px;
			border-radius: 5px;
			// overflow: hidden;
			margin-bottom: 20px;
			height: 40px;
			position: relative;
			.iconContainer{
				position: absolute;
				top: 0;
				left: 8px;
				height: 100%;
				// width: 100%;
				display: flex;
				align-items: center;
				svg{
					fill: $color3;
					height: 50%;
				}
			}
			input{
				border: none;
				width: 100%;
				padding: 3px;
				padding-left: 40px;
				// height: 100%;
				border-radius: 5px;
				color: $color3;
			}
			input:focus{
				// [ <outline-width> || <outline-style> || <outline-color> ] | inherit
				outline: 2px solid rgba($color3,0.5);
			}
		}
		.buttonsContainer{
			display: flex;
			flex-direction: row;
			width: 80%;
			margin-top: 20px;
			.button{
				// background-color: white;
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 1;
				height: 40px;
				border-radius: 5px;
				position: relative;
				.iconContainer{
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					width: auto;
					// margin-left: 24px;
					margin-right: 8px;

					img{
						height: 50%;
					}
					
				}
				.loadingStateContainer{
					position: absolute;
					right: 8px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					opacity: 0;
					svg{
						fill: $color3;
						position: absolute;
						right: 0;
						height: 50%;
						width: auto;
						-webkit-animation: rotating 2s linear infinite;
						-moz-animation: rotating 2s linear infinite;
						-ms-animation: rotating 2s linear infinite;
						-o-animation: rotating 2s linear infinite;
						animation: rotating 2s linear infinite;
					}
					transition: opacity 300ms ease;
				}
				.loadingStateContainer.active{
					opacity: 1;
				}
				label{

				}
			}
			.button{
				margin-right: 16px;
			}
			.button:last-child{
				margin-right: 0;
			}
		}
	}
}
