.SliderTable{
	table{
		thead{
			tr{
				th{
					height: 32px;
					width: 60px;
					padding: 0px;
					border-spacing: 0;
					div{
						height: 100%;
					}
					.content{
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}
		tbody{
			tr{
				td{
					width: 60px;
					padding: 0;
				}
			}
		}
	}
}
