@import './variables.scss';

.Units{
	// display: flex;
	// flex-direction: row;
	// height: min-content;
	// width: min-content;
	// padding-top: 8px;
	// padding-bottom: 16px;
	// padding-left: 16px;
	// padding-right: 16px;
	// overflow-x: auto;
	// flex: 1;
	.Unit{
		width: min-content;
		height: min-content;
		margin-left: 0px;
		// margin-right: 16px;
		.titleContainer{
			padding-top: 16px;
			padding-left: 24px;
			padding-right: 24px;
			display: flex;
			flex-direction: row;
			// justify-content: center;
			align-items: center;
			h3{
				flex: 1;
			}
		}
	}
	.UnitType{
		width: min-content;
		height: min-content;
		margin-left: 24px;
		.titleContainer{
			padding-top: 16px;
			padding-left: 24px;
			padding-right: 24px;
			display: flex;
			flex-direction: row;
			// justify-content: center;
			align-items: center;
			h3{
				flex: 1;
			}
		}
	}
	.addButtonContainer{
		margin-left: 0px;
		display: flex;	// overflow-y: auto;
		// overflow-x: hidden;
		padding-right: 12px;
		height: 32px;
		width: min-content;
		background-color: $color4;
		svg{
			fill: $color1;
			height: 50%;
			margin-right: 4px;
			// width: 100px;
		}
		h3{
			color: $color1;
		}
	}
}
.Unit.Neu{
	cursor: default;
}
.UnitType.Neu{
	cursor: default;
}

@media only screen
and (max-width: 1024px){
	.Units{
		flex-direction: column;
		.Unit{
			margin-bottom: 24px;
		}
		.UnitType{
			margin-left: 0px;
		}
	}
}