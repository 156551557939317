@import '../../styles/variables.scss';

.ElectricityAddModal{
	width: 320px;
	max-width: 80%;
	height: min-content;
	// min-height: 500px;
	padding: 24px;
	border-radius: 5px;
	background-color: $mainColor;
	display: flex;
	align-items: center;
	flex-direction: column;
	.titleContainer{
		h1{

		}
	}
	.PropertySelector.unitSelector{
		margin: 0;
		margin-top: 16px;
		max-width: calc(100% - 48px);
	}
	.formInputContainer{
		margin-top: 16px;
		margin-bottom: 16px;
		display: flex;
		width: auto;
		max-width: calc(100% - 48px);
		align-items: center;
		flex-direction: column;
		.photoDrop{
			// width: 100% !important;
			// aspect-ratio: 1 !important;
			margin-top: 16px;
		}
		.inputContainer{
			width: 256px;
			// max-width: calc(100% - 48px);
			max-width: 100%;
			height: 40px;
			border-radius: 5px;
			margin-bottom: 16px;
			// border-color: $color4;
			border: 1px solid $color3;
			input{
				width: calc(100% - 16px);
				height: 100%;
				border-radius: 5px;
				padding: 0;
				padding-left: 16px;
				border: 0px;
				
			}
		}
	}
	.buttonsContainer{
		display: flex;
		flex-direction: row;
		padding: 16px 0px;
		justify-content: flex-end;
		.buttonContainer{
			height: 40px;
			width: min-content;
			padding: 0px 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: white;
			border-radius: 5px;
			box-shadow: 
				4.5px 4.5px 8px rgba($darkShadow,0.6);
				// -4.5px -4.5px 8px rgba($lightShadow, 0.5);
			margin-left: 16px;
			h3{
				
			}
		}
		.buttonContainer:hover{
			cursor: pointer;
		}
		.buttonContainer:first-child{
			margin-left: 0;
		}
	}
}