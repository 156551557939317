.clearButtonHack::-webkit-inner-spin-button
// .clearButtonHack::-webkit-clear-button
{
    display: none; /* Hide the button */
    -webkit-appearance: none; /* turn off default browser styling */
}

.RatesModal{
	.dataInput{
		
	}
}
@media only screen and (max-width: 1024px){
	.RatesModal{
		.dataInput{
			flex-direction: column !important;
			div:nth-child(1){
				margin-bottom: 8px;
			}
		}
	}
}
.clearButtonHack::-webkit-calendar-picker-indicator { 
	height: 16px;
	width: 16px;
}
