@import '../../styles/variables.scss';
.Header{
	height: 64px;
	min-height: 64px;
	padding-left: 24px;
	padding-right: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	// .Notification{
	// 	margin-left: 0;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	position: relative;
	// 	width: 40px;
	// 	min-width: 40px;
	// 	height: 40px;
	// 	.numberBadge{
	// 		position: absolute;
	// 		top: 3px;
	// 		right: 3px;
	// 		width: 18px;
	// 		height: 18px;
	// 		background-color: red;
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;
	// 		border-radius: 12px;
	// 		label{
	// 			color: white;
	// 			font-size: 10px;
	// 		}
	// 	}
	// 	svg{
	// 		height: 70%;
	// 	}
	// }
	.linkTitleContainer{
		flex: 1;
		h1{}
	}
	.menuContainer{
		display: none;
		position: relative;
		width: 40px;
		min-width: 40px;
		min-height: 40px;
		height: 40px;
		max-width: 40px;
		max-height: 40px;
		.E{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 50%;
			height: 50%;
			opacity: 1;
			transition: opacity 300ms ease;
		}
		.X{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 40%;
			height: 40%;
			opacity: 0;
			transition: opacity 300ms ease;
		}
	}
	.menuContainer.active{
		.E{
			opacity: 0;
		}
		.X{
			opacity: 1;		margin-right: 16px;
		}
	}
	.Neu{
		margin-right: 16px;
	}
	.Neu:last-child{
		margin-right: 0px;
	}
	// .NotificationItem{
	// 	width: auto;
	// 	padding: 12px;
	// 	border-bottom: 1px solid rgba($color3,0.3);
	// 	.notificationTitleContainer{
	// 	}
	// 	.notificationContentContainer{
	// 		p{
	// 			line-height: 16px;
	// 		}
	// 	}
	// 	transition: all 150ms ease;
	// }
	// .NotificationItem:hover{
	// 	background-color: rgba($color4,1);
	// 	color: white
	// }
	// .NotificationItem:last-child{
	// 	border-bottom: none;
	// }
}

@media only screen
and (max-width: 1024px){
	.menuContainer{
		display: flex !important;
	}
	.linkTitleContainer{
		flex: 1;
		h1{
			// font-size: 14px;
		}
	}
}