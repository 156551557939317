@import '../styles/variables.scss';

.actionContainer{
	display: flex;
	flex-direction: row;
	.actionButton{
		margin: 0;
		width: 25px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg{
			width: 50%;
			fill: $color4;
		}
	}
	.actionButton:first-child{
		margin-right: 8px;
	}
}

@media only screen
and (max-width: 1024px){
	.Rates{
		flex-direction: column;
	}
}