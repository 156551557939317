@import '../../styles/variables.scss';
.NeuToggle{
	border-radius: 30px;
	height: 24px;
	width: 50px;
	// background-color: rgba();
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	margin: 0;
	box-shadow: 
		inset 2px 2px 4px rgba($darkShadow,0.6), 
		inset -2px -2px 4px rgba($lightShadow, 0.5), 
		4.5px 4.5px 8px rgba($darkShadow,0.6), 
		-4.5px -4.5px 8px rgba($lightShadow, 0.5);	
	.toggleIndicator{
		box-shadow: 
			inset 2px 2px 4px rgba($darkShadow,0.6), 
			inset -1px -1px 4px rgba($lightShadow, 0.5);
		background-color: rgba($color4, 0.1);
		width: 100%;
		height: 100%;
		transition: background 300ms ease;
		border-radius: 300px;
		position: relative;
		.toggle{
			height: 20px;
			width: 20px;
			// aspect-ratio: 1/1;
			border-radius: 30px;
			background-color: $color4;
			position: absolute;
			top: 50%;
			left: 3px;
			// right: auto;
			transform: translate(0%,-50%);
			transition: left 300ms ease;
			z-index: 2;
		}
		.toggleTextOff{
			position: absolute;
			right: 8px;
			top: 50%;
			transform: translate(0,-50%);
			font-size: 10px;
			transition: opacity 300ms ease;
			opacity: 1;
			z-index: 1;
		}
		.toggleTextOn{
			position: absolute;
			left: 8px;
			top: 50%;
			transform: translate(0,-50%);
			font-size: 10px;
			transition: opacity 300ms ease;
			opacity: 0;
			color: $color1;
			z-index: 1;
		}
	}
}
.NeuToggle.active{
	.toggleIndicator{
		background-color: rgba($color4, 0.5);
		position: relative;
		.toggle{
			// right: 10%;
			left: 27px;
			// left: auto;
		}
		.toggleTextOff{
			opacity: 0;
		}
		.toggleTextOn{
			opacity: 1;
		}
	}
}