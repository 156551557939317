@import '../../styles/variables.scss';

.PropertySelector{
	// margin-top: 8px;
	width: 256px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	margin-left: 16px;
	.labelContainer{
		flex: 1;
		margin-left: 16px;
	}
	.dropIconContainer{
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 16px;
		transition: transform 300ms ease;
		img{
			height: 40%;
		}
	}
	.DropItem{
		height: 40px;
		padding-left: 16px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background-color: rgba($color4,0);
		transition: background 300ms ease;
		h5{

		}
	}
	.DropItem:hover{
		background-color: rgba($color4,0.5);
	}
	.addButton{
		background-color: rgba($color4,0.8);
		color: white
	}
}
.PropertySelector:hover{
	cursor: pointer;
}
.PropertySelector.active{
	.dropIconContainer{
		transform: rotate(90deg);
	}
}

.AddPropertyModal{
	width: 512px;
	max-width: 80%;
	padding: 24px;
	border-radius: 5px;
	// background-color: rgba($color1, 0.8);
	// backdrop-filter: blur(3px);
	background-color: $color1;
	.titleContainer{
		margin-bottom: 24px;
		h1{
		}
	}
	form.inputContainer{
		width: 100%;
		.input{
			border-radius: 5px;
			height: 32px;
			margin-bottom: 20px;
			border: 2px solid #2c2c2c;
			width: 100%;
			input{
				height: 100%;
				width: calc(100% - 16px) !important;
				padding: 0;
				padding-left: 16px;
				margin: 0;
				border-radius: 5px;
				border: 0px;
			}
		}
	}
	.coordinateInputContainer{
		.separator{
			width: 24px;
		}
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		.input{
			flex:1;
		}
	}
	.buttonsContainer{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		.separator{
			width: 24px;
		}
		button{
			// width: 40%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1;
			height: 40px;
			border-radius: 5px;
			border: 0px;
			box-shadow: 4px 4px 16px rgba(#2c2c2c,0.5);
		}
		button:hover{
			cursor: pointer;
		}
		.add{
			background-color: black;
			color:white
		}
		.cancel{

		}
	}
}

@media only screen{
	
}