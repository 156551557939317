@import './variables.scss';
// @import './print.scss';

.bookingHover{
	background-color: #2c2c2c;
	transition: all 300ms ease;
	overflow: hidden;
	:hover{
		background-color: rgb(237, 50, 55);
	}
}

