@import '../../styles/variables.scss';
.TagInput{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	max-width: 100%;
	// width: 100px;
	// height: 100px;
	.TagLabel{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 4px 10px;
		width: min-content;
		height: min-content;
		border-radius: 100px;
		background-color: rgba($color3,1);
		margin: 4px;
		.tagText{
			width: min-content;
			h3{
				white-space: nowrap;
				color: white
			}
		}
		.TagDelete{
			margin-left: 8px;
			height: 20px;
			width: 20px;
			border-radius: 100px;
			background-color: rgba($color4,0.5);
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 300ms ease;
			svg{
				fill: white;
				height: 50%;
				// width: %0%;
			}
		}
		.TagDelete:hover{
			background-color: rgba($color2,0.5);
			cursor: pointer;
		}
	}
}