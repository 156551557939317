/*
// Colors
$baseColor : #151934;
$mainColor : #1C2340;
$accentColor1 : #3663FE;
$accentColor2 : #32395E;
$accentColor3 : #252C4B;

// FontSize :
$smallFont : 14;
$mediumFont : 16;
$largeFont : 18;

// FontWeight : 
$lightFont : 400;
$normalFont : 600;
$boldFont : 800;

// FontFamily : 
$font1 : 'halvetica';
$font2 : 'halvetica';
$font3 : 'halvetica';
*/
$mainColor: #E0E5EC;
$lightShadow: #FFFFFF;
$darkShadow: #A3B1C6;

$color1: #FFFFFF;
$color2: #E3EDF7;
$color3: #7998BC;
$color4: #22304A;
