@import './variables.scss';

.Main{
	height: 100%;
	width: 100%;
	// width: 100vw;
	display: flex;
	flex-direction: row;
	.mainLeftContainer{
		position: relative;
		width: 256px;
		flex: 0 ;
	}
	.mainRightContainer{
		display: flex;
		flex: 1;
		// flex-shrink: 1;
		flex-direction: column;
		overflow-x: hidden;
		.authenticatedPage{
			height: 100%;
			overflow-y: auto;
		}
	}
}
@media only screen 
and (max-width: 1024px) 
{
	.mainRightContainer{
		// width: 100%;
	}
	.mainLeftContainer{
		width: 0px !important;
	}
}